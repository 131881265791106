import React, {Component} from 'react';
import Utils from './Utils';

export default class QualityDot extends Component
{


  componentDidMount()
  {
  }

  render()
  {
    return (
      <div className="QualityDot" style={{backgroundColor: Utils.QUALITY_COLORS[this.props.quality]}}/>);
  }

}