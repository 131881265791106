import React, {Component} from 'react';
import {VictoryPie} from "victory";
import "./CircleChart.css";
import Utils from "./Utils";

export default class CircleChart extends Component
{
// data {quality , percent}
// quality  = 0 索引 颜色档位
// nextQuality
// , val = 90
// , max = 115 , min=76

  constructor(props)
  {
    super(props);
    this.state = {
      data: []
      , color: Utils.QUALITY_COLORS[0]
      , color2: Utils.QUALITY_COLORS[0]
    }
  }


  componentWillReceiveProps(nextProps, nextContext)
  {
    let { data , val } = nextProps;
    let {quality ,percent }= data;
    this.setState({
      data: this.getData(percent)
      , color: Utils.QUALITY_COLORS[quality]
      , color2: Utils.QUALITY_COLORS[quality + 1]
    });
  }


  getData(percent)
  {
    //1是本档  2是下一档 ; percent
    return [{x: 1, y: 100 * percent}, {x: 2, y: 100 * (1-percent)}];
  }

  render()
  {
    let {color,color2, data} = this.state;
    let {val} = this.props;
    return (

      <div className="CircleChart">
        <VictoryPie

          data={data}
          animate={{duration: 1000}}
          labels={() => null}
          // startAngle={0 + 120}
          // endAngle={360 + 120}
          innerRadius={120}
          cornerRadius={0}

          style={{
            data: {
              fill: ({datum}) => {
                return datum.x === 1 ? color : color2;
              }
            }
          }}
        />
        <div className="valTf">{val}</div>
      </div>

    );
  }

}