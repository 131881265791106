
import React, {Component} from 'react';
import "./GreenRectAnim.css";

export default class GreenRectAnim extends Component
{


  componentDidMount()
  {
  }
  render(){
    return (
      <div className="GreenRectAnim">
        <img src={require("./res/GreenRectAnim.gif")} />
        {/*<div className="GreenRectAnimLi"></div>*/}
        {/*<div className="GreenRectAnimLi"></div>*/}
        {/*<div className="GreenRectAnimLi"></div>*/}
        {/*<div className="GreenRectAnimLi"></div>*/}
        {/*<div className="GreenRectAnimLi"></div>*/}
        {/*<div className="GreenRectAnimLi"></div>*/}
      </div>);
  }

}