
const PM25_GRADES = [0,36,76,116,151,251];
const PM10_GRADES = [0,51,151,251,351,421];
const CO2_GRADES = [0,800,1000,1500,2420,3500];
const CO_GRADES = [0,6,11,36,61,91];
const O3_GRADES = [0,161,201,301,401,801];
const SO2_GRADES = [0,151,501,651,801,3500];
const NO2_GRADES = [0,101,201,701,1201,2341];
const AQI_GRADES = [0,51,101,151,201,300];
export default class Utils
{

  static  QUALITY_COLORS = ["#00ff00"
  , "#fff205"
  , "#ff5a05"
  , "#ff1111"
  , "#e111ff"
  , "#9e0000"
  , "#9e0000"//防止溢出
];
  static  QUALITY_DESC = ["优秀"
  , "良好"
  , "轻度污染"
  , "中度污染"
  , "重度污染"
  , "严重污染"
  , "严重污染"//防止溢出
];


  static calcAQIQuality(aqi)
  {
    if (aqi >= AQI_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (aqi >= AQI_GRADES[4])
    {
      return {quality : 4 , percent : (1- (aqi-AQI_GRADES[4])/(AQI_GRADES[5]-AQI_GRADES[4])) } ;
    } else if (aqi >= AQI_GRADES[3])
    {
      return {quality : 3 , percent : (1- (aqi-AQI_GRADES[3])/(AQI_GRADES[4]-AQI_GRADES[3])) } ;
    } else if (aqi >= AQI_GRADES[2])
    {
      return {quality : 2 , percent : (1- (aqi-AQI_GRADES[2])/(AQI_GRADES[3]-AQI_GRADES[2])) } ;
    } else if (aqi >= AQI_GRADES[1])
    {
      return {quality : 1 , percent : (1- (aqi-AQI_GRADES[1])/(AQI_GRADES[2]-AQI_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-aqi/AQI_GRADES[1])} ;
    }
  }
  static calcPm25Quality(pm25)
  {
    if (pm25 >= PM25_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (pm25 >= PM25_GRADES[4])
    {
      return {quality : 4 , percent : (1- (pm25-PM25_GRADES[4])/(PM25_GRADES[5]-PM25_GRADES[4])) } ;
    } else if (pm25 >= PM25_GRADES[3])
    {
      return {quality : 3 , percent : (1- (pm25-PM25_GRADES[3])/(PM25_GRADES[4]-PM25_GRADES[3])) } ;
    } else if (pm25 >= PM25_GRADES[2])
    {
      return {quality : 2 , percent : (1- (pm25-PM25_GRADES[2])/(PM25_GRADES[3]-PM25_GRADES[2])) } ;
    } else if (pm25 >= PM25_GRADES[1])
    {
      return {quality : 1 , percent : (1- (pm25-PM25_GRADES[1])/(PM25_GRADES[2]-PM25_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-pm25/PM25_GRADES[1])} ;
    }
  }
  static calcPm10Quality(pm10)
  {
    if (pm10 >= PM10_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (pm10 >= PM10_GRADES[4])
    {
      return {quality : 4 , percent : (1- (pm10-PM10_GRADES[4])/(PM10_GRADES[5]-PM10_GRADES[4])) } ;
    } else if (pm10 >= PM10_GRADES[3])
    {
      return {quality : 3 , percent : (1- (pm10-PM10_GRADES[3])/(PM10_GRADES[4]-PM10_GRADES[3])) } ;
    } else if (pm10 >= PM10_GRADES[2])
    {
      return {quality : 2 , percent : (1- (pm10-PM10_GRADES[2])/(PM10_GRADES[3]-PM10_GRADES[2])) } ;
    } else if (pm10 >= PM10_GRADES[1])
    {
      return {quality : 1 , percent : (1- (pm10-PM10_GRADES[1])/(PM10_GRADES[2]-PM10_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-pm10/PM10_GRADES[1])} ;
    }
  }
  static calcCo2Quality(val)
  {
    if (val >= CO2_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (val >= CO2_GRADES[4])
    {
      return {quality : 4 , percent : (1- (val-CO2_GRADES[4])/(CO2_GRADES[5]-CO2_GRADES[4])) } ;
    } else if (val >= CO2_GRADES[3])
    {
      return {quality : 3 , percent : (1- (val-CO2_GRADES[3])/(CO2_GRADES[4]-CO2_GRADES[3])) } ;
    } else if (val >= CO2_GRADES[2])
    {
      return {quality : 2 , percent : (1- (val-CO2_GRADES[2])/(CO2_GRADES[3]-CO2_GRADES[2])) } ;
    } else if (val >= CO2_GRADES[1])
    {
      return {quality : 1 , percent : (1- (val-CO2_GRADES[1])/(CO2_GRADES[2]-CO2_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-val/CO2_GRADES[1])} ;
    }
  }
  static calcCoQuality(val)
  {
    if (val >= CO_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (val >= CO_GRADES[4])
    {
      return {quality : 4 , percent : (1- (val-CO_GRADES[4])/(CO_GRADES[5]-CO_GRADES[4])) } ;
    } else if (val >= CO_GRADES[3])
    {
      return {quality : 3 , percent : (1- (val-CO_GRADES[3])/(CO_GRADES[4]-CO_GRADES[3])) } ;
    } else if (val >= CO_GRADES[2])
    {
      return {quality : 2 , percent : (1- (val-CO_GRADES[2])/(CO_GRADES[3]-CO_GRADES[2])) } ;
    } else if (val >= CO_GRADES[1])
    {
      return {quality : 1 , percent : (1- (val-CO_GRADES[1])/(CO_GRADES[2]-CO_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-val/CO_GRADES[1])} ;
    }
  }
  static calcO3Quality(val)
  {
    if (val >= O3_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (val >= O3_GRADES[4])
    {
      return {quality : 4 , percent : (1- (val-O3_GRADES[4])/(O3_GRADES[5]-O3_GRADES[4])) } ;
    } else if (val >= O3_GRADES[3])
    {
      return {quality : 3 , percent : (1- (val-O3_GRADES[3])/(O3_GRADES[4]-O3_GRADES[3])) } ;
    } else if (val >= O3_GRADES[2])
    {
      return {quality : 2 , percent : (1- (val-O3_GRADES[2])/(O3_GRADES[3]-O3_GRADES[2])) } ;
    } else if (val >= O3_GRADES[1])
    {
      return {quality : 1 , percent : (1- (val-O3_GRADES[1])/(O3_GRADES[2]-O3_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-val/O3_GRADES[1])} ;
    }
  }
  static calcSo2Quality(val)
  {
    if (val >= SO2_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (val >= SO2_GRADES[4])
    {
      return {quality : 4 , percent : (1- (val-SO2_GRADES[4])/(SO2_GRADES[5]-SO2_GRADES[4])) } ;
    } else if (val >= SO2_GRADES[3])
    {
      return {quality : 3 , percent : (1- (val-SO2_GRADES[3])/(SO2_GRADES[4]-SO2_GRADES[3])) } ;
    } else if (val >= SO2_GRADES[2])
    {
      return {quality : 2 , percent : (1- (val-SO2_GRADES[2])/(SO2_GRADES[3]-SO2_GRADES[2])) } ;
    } else if (val >= SO2_GRADES[1])
    {
      return {quality : 1 , percent : (1- (val-SO2_GRADES[1])/(SO2_GRADES[2]-SO2_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-val/SO2_GRADES[1])} ;
    }
  }
  static calcNo2Quality(val)
  {
    if (val >= NO2_GRADES[5])
    {
      return { quality : 5 , percent : 1};
    } else if (val >= NO2_GRADES[4])
    {
      return {quality : 4 , percent : (1- (val-NO2_GRADES[4])/(NO2_GRADES[5]-NO2_GRADES[4])) } ;
    } else if (val >= NO2_GRADES[3])
    {
      return {quality : 3 , percent : (1- (val-NO2_GRADES[3])/(NO2_GRADES[4]-NO2_GRADES[3])) } ;
    } else if (val >= NO2_GRADES[2])
    {
      return {quality : 2 , percent : (1- (val-NO2_GRADES[2])/(NO2_GRADES[3]-NO2_GRADES[2])) } ;
    } else if (val >= NO2_GRADES[1])
    {
      return {quality : 1 , percent : (1- (val-NO2_GRADES[1])/(NO2_GRADES[2]-NO2_GRADES[1])) } ;
    } else
    {
      return {quality : 0 , percent : (1-val/NO2_GRADES[1])} ;
    }
  }


  static calcSkyIcon ( val )
  {
    switch( val)
    {
      case "晴": return require("./res/w/qingtian.png");
      case "晴到多云": return require("./res/w/jubuqingtian.png");
      case "多云": return require("./res/w/duoyun.png");
      case "阴": return require("./res/w/duoyun.png");
      case "小雨": return require("./res/w/xiaoyu.png");
      case "阵雨": return require("./res/w/xiaoyu.png");
      case "小（阵）雨": return require("./res/w/xiaoyu.png");
      case "中雨": return require("./res/w/zhongyu.png");
      case "大雨": return require("./res/w/dayu.png");
      case "大到暴雨": return require("./res/w/dayu.png");
      case "雷阵雨": return require("./res/w/leizhenyu.png");
      case "雨夹雪":
      case "小雪":
      case "中雪":
      case "大到暴雪":
      case "冰雹":
      case "霜冻":
        return require("./res/w/xue.png");
      case "雾":return require("./res/w/wu.png");
    }
    return require("./res/w/duoyun.png");
  }
  static calcSkyBg ( val )
  {
    switch( val)
    {
      case "晴": return require("./res/w/bg_qingtian.png");
      case "晴到多云":
      case "多云":
        return require("./res/w/bg_duoyu.png");
      case "阴":
      case "小雨":
      case "阵雨":
      case "小（阵）雨":
      case "中雨":
      case "大雨":
      case "大到暴雨":
      case "雷阵雨": return require("./res/w/rain.png");
      case "雨夹雪":
      case "小雪":
      case "中雪":
      case "大到暴雪":
      case "冰雹":
      case "霜冻":
        return require("./res/w/bg_xue.png");
      case "雾":return require("./res/w/bg_duoyu.png");
    }
    return require("./res/w/bg_duoyu.png");
  }


  static calcWarmStr(val)
  {
    if(val>=35)
    {
      return "温度炎热";
    }else if (val >=27) {
      return "温度过暖";
    }else if (val>=18)
    {
      return "温度舒适";
    }else if (val>=10)
    {
      return "温度过凉";
    }else
      return "温度寒冷";
  }
  static calcHumStr(val)
  {
    if(val>=89)
    {
      return "湿度潮湿";
    }else if (val >=61) {
      return "湿度潮润";
    }else if (val>=40)
    {
      return "湿度柔润";
    }else if (val>=20)
    {
      return "湿度干燥";
    }else
      return "湿度干枯";
  }

  static calcWeekday()
  {

    let today  =  new Date();
    let date = "";//(today.getMonth() + 1)+"-"+(today.getDate())+" ";
    switch(today.getDay() )
    {
      case 1: date += "星期一";break;
      case 2: date += "星期二";break;
      case 3: date += "星期三";break;
      case 4: date += "星期四";break;
      case 5: date += "星期五";break;
      case 6: date += "星期六";break;
      case 0: date += "星期日";break;
    }
    return date;
  }


}