import React, {Component} from 'react';
import "./MainPage.css";
import Facade from "./Facade";
import { VictoryPie } from "victory";
import CircleChart from "./CircleChart";
import AirQualityGrade from "./AirQualityGrade";
import QualityDot from "./QualityDot";
import Utils from "./Utils";
import BarChart from "./BarChart";
import GreenRectAnim from "./GreenRectAnim";
import DeltaIcon from "./DeltaIcon";

export default class MainPage extends Component
{


  constructor(props)
  {
    super(props);

    this.state = {
      curRoomIndex: 0,
      date: Utils.calcWeekday(),
      outdoorData:{}
    };
  }

  componentDidMount()
  {
    Facade.model.fetchRoomList( ()=>{

      this.indoorItv = setInterval( ()=>{
        Facade.model.refreshRoomList(()=>{
          //有至少一个房间获得了数据
          this.forceUpdate();
        });
      } ,16000);//16000

      this.outdoorItv = setInterval( ()=>{
        this.updateOutdoor();
      } ,600000);

      this.sliderItv = setInterval(() => {
        this.slideRoom();
      }, 8000);

      Facade.model.refreshRoomList(()=>{
        //有至少一个房间获得了数据
        this.forceUpdate();
      });
      this.updateOutdoor();
    });




  }

  updateOutdoor(){
    Facade.model.refreshOutdoor(
      ( )=>{
        this.setState({date: Utils.calcWeekday(), outdoorData:Facade.model.outdoorData});
      }
    );
  }

  slideRoom()
  {
    //切换显示的房间
    let {curRoomIndex} = this.state;
    if (curRoomIndex == 0)
    {
      curRoomIndex = 1;
    } else curRoomIndex = 0;
    this.setState({curRoomIndex});
  }

  componentWillUnmount()
  {
    clearInterval(this.indoorItv);
    clearInterval(this.sliderItv);
    clearInterval(this.outdoorItv);
  }




  render()
  {
    let {date,  curRoomIndex, outdoorData} = this.state;
    let roomVO  = Facade.model.rooms[curRoomIndex] || {};
    let names = Facade.model.getRoomNames();
    let aqiQuality = Utils.calcAQIQuality(outdoorData.aqi);
    return (
      <div  >
        <div className="MainPage">


          <div className="MainPageLeft">
            <div className="mainTitle">
              <img src={require("./res/loction.png")} alt="" className="location"/>
              <div className="tf">交大安泰空气质量监测</div>
              <GreenRectAnim />
              <img src={require("./res/logo.png")}  className="logo"  />
            </div>

            <div className="roomTabBar">
              <div className="roomTabTitle">室内</div>
              <div className={"roomTabLi " + (curRoomIndex==0?"active":"")}>
                {names[0] ||""}
              </div>
              <div className={"roomTabLi " + (curRoomIndex==1?"active":"")}>
                {names[1] ||""}
              </div>
              <div className="pubTime">
                <div className="pubTimeTf">{roomVO.publish_time} 发布</div>
              </div>
            </div>
            <div className="roomTabContainerBg"/>
            <div className="SingleRoomBoardHr"/>
            <div className="SingleRoomTemperature">
              <div className="TemperatureCol">
                <img src={require("./res/T.png")} alt="" className="TemperatureIcon"/>
                {Utils.calcWarmStr(roomVO.temp)}
                <div className="TemperatureValTf">
                  {roomVO.temp}°C
                </div>
              </div>
              <div style={{width:"20px"}}></div>
              <div className="TemperatureCol">
                <img src={require("./res/water.png")} alt="" className="TemperatureIcon"/>
                {Utils.calcHumStr(roomVO.hum)}
                <div className="TemperatureValTf">
                  {roomVO.hum}%
                </div>
              </div>
            </div>

            {this.renderPmBoard("PmBoard2", Utils.calcPm25Quality, roomVO.pm25 || 1, "PM2.5/微尘","#149D3A"
            ,Facade.model.calcDelta("pm25", roomVO))}
            {this.renderPmBoard("PmBoard2 right", Utils.calcPm10Quality, roomVO.pm10 || 1, "PM10/飘尘","#83149D"
            ,Facade.model.calcDelta("pm10", roomVO))}

            {this.renderCo2Board("Co2Board3" ,Utils.calcCo2Quality ,roomVO.co2 || 1,"CO₂","二氧化碳")}


            <AirQualityGrade/>
          </div>





          <div className="MainPageRight">


            <div className="AQIBg" />
            <div className="Weather" >
              <img className="WeatherBgImg" src={Utils.calcSkyBg(outdoorData.sky)}/>
              <div className="WeatherCity">上海市</div>
              <div className="WeatherRow">
                <div className="WeatherWeekday">{date}</div>
                <div className="WeatherVal">{outdoorData.sky}
                  <img src={Utils.calcSkyIcon(outdoorData.sky)} alt="" className="WeatherDescIcon" />
                </div>
                <div className="WeatherT">{outdoorData.temp}°C</div>
              </div>
            </div>
            <div className="AQIBar">
              <div className="AQITitle">
                <img src={require("./res/AQI.png")} alt=""/>
                AQI
              </div>
              <div className="AQIVal">{outdoorData.aqi}</div>
              <div className="AQIDesc">
                <div style={{color: Utils.QUALITY_COLORS[aqiQuality.quality]}}>
                  {Utils.QUALITY_DESC[aqiQuality.quality]}
                </div>
                <DeltaIcon delta={Facade.model.calcDelta("aqi",outdoorData)}/>
              </div>
            </div>

            {this.renderPmBoard("PmBoard2 out1", Utils.calcPm25Quality, outdoorData.pm25 || 1, "PM2.5/微尘", "#149D3A"
              , Facade.model.calcDelta("pm25", outdoorData))}
            {this.renderPmBoard("PmBoard2 out2", Utils.calcPm10Quality, outdoorData.pm10 || 1, "PM10/飘尘","#83149D"
              ,Facade.model.calcDelta("pm25", outdoorData))}

            {this.renderCo2Board("Co2Board3 out1", Utils.calcCoQuality, outdoorData.co || 1, "CO", "一氧化碳")}
            {this.renderCo2Board("Co2Board3 out2", Utils.calcO3Quality, outdoorData.o3 || 1, "O₃", "臭氧")}
            {this.renderCo2Board("Co2Board3 out3", Utils.calcSo2Quality, outdoorData.so2 || 1, "SO₂", "二氧化硫")}
            {this.renderCo2Board("Co2Board3 out4", Utils.calcNo2Quality, outdoorData.no2 || 1, "NO₂", "二氧化氮")}



          </div>


        </div>
      </div>);
  }

  renderPmBoard(className, fnc, val, label, titleColor, delta)
  {
    let qualityObj = fnc(val);
    return (
      <div className={className}>
        <div className="PmBoard2Title">
          <div className="QualityDot" style={{backgroundColor: titleColor}}/>
          {label}
        </div>
        <CircleChart data={qualityObj}
                     val={val}
        />
        <div className="PmBoard2UnitTf">
          µg/m³
        </div>
        <div className="PmBoard2Desc">
          <div style={{color: Utils.QUALITY_COLORS[qualityObj.quality]}}>
            {Utils.QUALITY_DESC[qualityObj.quality]}
          </div>
          <DeltaIcon delta={delta}/>
        </div>
      </div>
    );
  }

  renderCo2Board(className, fnc, val, label,label2)
  {
    let qualityObj = fnc(val);

    return (
      <div className={className}>
        <div className="Co2Board3Label">{label}</div>
        <div className="Co2Board3Label2">{label2}</div>
        <div className="Co2Board3Val">{val}</div>

        <BarChart data={qualityObj} />
      </div>
    );
  }

}


