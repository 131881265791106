import React, {Component} from 'react';


export default class DeltaIcon extends Component
{


  componentDidMount()
  {
  }

  render()
  {
    let {delta } = this.props;
    if ( delta == 0 || delta==undefined) return null;
    let src = delta >0 ? require("./res/down.png"): require("./res/up.png");
    return (
      <img src={src} alt="" className="PmBoard2DescIcon"/>
    );
  }

}