import React, {Component} from 'react';
import "./BarChart.css";
import Utils from "./Utils";


export default class BarChart extends Component
{
//data


  render()
  {

    let {data, val} = this.props;
    let {quality, percent} = data;

    return (
      <div className="BarChart">
        <div className="BarTrack" style={{ backgroundColor: Utils.QUALITY_COLORS[quality+1]}}></div>
        <div className="BarBar" style={{width: (100*percent)+"%" ,backgroundColor: Utils.QUALITY_COLORS[quality]}}></div>
      </div>);
  }

}