import Facade from "./Facade";
import Utils from "./Utils";

export default class AQModel
{
  constructor()
  {

  }

  /** 室内信息
   {
   "id": "7600CB8449B7",  //设备id
   "name": "安泰经管会议室1"

        "temp": "20", //温度
        "hum": "69",  //湿度%
        "pm25": "3",  //pm2.5
        "pm10": "6",  //pm10
        "hcho": "",   //甲醛，交大设备不采集co2、hcho
        "co2": "",  //二氧化碳，交大设备不采集co2、hcho
        "publish_time": "2019-09-19 14:13:23" //发布时间

        // 客户端追加:
        "prev" :{...}
   }
   */
  rooms = [];
  deviceList = [];


  // 一次 请求device列表 即room列表
  fetchRoomList(cb)
  {
    Facade.gw.getDevice(10001, (deviceList) => {
      this.deviceList = deviceList;
      cb();
    });
  }

  refreshRoomList(cb)
  {
    this.deviceList.map((eachDevice, i) => {
      Facade.gw.getIndoor(eachDevice.id, (indoorData) => {
        let roomData = {...eachDevice, ...indoorData, prev: this.rooms[i]};
        this.rooms[i] = roomData;
        if(cb!=null) cb();
      })
    })
  }

  getRoomNames()
  {
    return this.deviceList.map((li) => {
      return li.name;
    });
  }

  /** 计算增减变化趋势 0平  1增大  -1减小 */
  calcDelta(fieldName, vo)
  {
    if (vo.prev != null && vo.prev[fieldName]!=undefined)
    {
      let val = vo[fieldName] || 1;
      let prevVal = vo.prev[fieldName] || 1;
      if ( val > prevVal)
      {
        return 1;
      }
      if ( val < prevVal)
      {
        return -1;
      }
    }


    return 0;
  }


  ///
  outdoorData = {};

  refreshOutdoor(cb){
    Facade.gw.getOutdoor("上海市", (outdoorData)=>{
      outdoorData.prev = this.outdoorData;
      this.outdoorData = outdoorData;
      if (cb!=null) cb();
    })
  }


}