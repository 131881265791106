import Notify from "./Notify";
import Facade from "./Facade";

/**
 * Created by rhett on 2019/9/23.
 */
const SERVER_API = "/api";
export default class Gateway
{

  token = "";


  getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++)
    {
      var pair = vars[i].split("=");
      if (pair[0] == variable)
      {
        return pair[1];
      }
    }
    return (false);
  }


  //
  gwAlert = alert;

  gwSimpleCall(api, extraArg, cb, errCb)
  {
    let formData = new FormData();
    for (let eachKey in extraArg)
    {
      formData.append(eachKey, extraArg[eachKey]);
    }

    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    fetch(SERVER_API + api, {headers, method: "post", credentials: "include", body: formData})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (resp.error == 0)
        {
          cb(resp.data);
        } else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.message);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message);
      console.error(error);
    });

  }

  gwSimpleGet(api, extraArg, cb)
  {
    let url = SERVER_API + api;
    if (extraArg)
    {
      let args = [];
      for (let eachKey in extraArg)
      {
        args.push(eachKey + "=" + extraArg[eachKey]);
      }
      if (args.length)
        url = url + "?" + args.join("&");
    }
    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    fetch(url, {headers, method: "get", credentials: "include"})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (resp.error == 0)
        {
          cb(resp.data);
        } else
        {
          alert(resp.message);
        }

      }.bind(this)).catch((error) => {
      console.error(error);
    })
  }


  /**
   * 1.根据场景获取设备列表
   GET:https://air.iuxlabs.com/api/device

   参数
   scene：目前交大为10001，城建地产大厦为10000

   返回
   {
    "error": 0,
    "message": "success",
    "data": [
        {
            "id": "7600CB8449B7",  //设备id
            "name": "安泰经管会议室1"
        },
        {
            "id": "7600CB7F3DE9",
            "name": "安泰经管会议室2"
        }
    ]
}
   */
  getDevice(scene, cb)
  {
    this.gwSimpleGet("/device", {scene}, cb);
  }


  /**
   * 2.查询室内空气（建议10-12秒轮询）
   GET:https://air.iuxlabs.com/api/indoor

   参数：
   deviceId：设备id

   返回
   {
    "error": 0,
    "message": "success",
    "data": {
        "temp": "20", //温度
        "hum": "69",  //湿度%
        "pm25": "3",  //pm2.5
        "pm10": "6",  //pm10
        "hcho": "",   //甲醛，交大设备不采集co2、hcho
        "co2": "",  //二氧化碳，交大设备不采集co2、hcho
        "publish_time": "2019-09-19 14:13:23" //发布时间
    }
}
   */
  getIndoor(deviceId, cb)
  {
    this.gwSimpleGet("/indoor", {deviceId}, cb);
  }

  /**
   * 3.查询室外空气（建议10分钟轮询，数据来源：中央气象台、上海市空气质量实时发布系统）
   GET:https://air.iuxlabs.com/api/outdoor

   参数
   city：目前只支持 上海市

   返回
   {
    "error": 0,
    "message": "success",
    "data": {
        "temp": 26.7, //温度
        "hum": 47,    //湿度%
        "sky": "多云", //天气：晴、晴到多云、多云、阴、小（阵）雨、中雨、大雨、大到暴雨、 雷阵雨、雨夹雪、小雪、中雪、大到暴雪、冰雹、霜冻、雾
        "pm25": 12,  //pm2.5
        "pm10": 33,  //pm10
        "aqi": 33,   //空气质量指数
        "publish_time": "2019-09-19 13:45:00" //发布时间
        "o3":100,
        "co":20,
        "so2":43,
        "no2":23
    }
}
   */
  getOutdoor(city, cb)
  {
    this.gwSimpleGet("/outdoor", {city}, cb);
  }


}