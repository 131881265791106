import React, {Component} from 'react';
import Utils from "./Utils";


export default class AirQualityGrade extends Component
{


  componentDidMount()
  {
  }

  render()
  {
    return (
      <div className="AirQualityGrade">
        <div className="QualityGradeUl">
          空气等级:

          {[0, 1, 2, 3, 4, 5].map((li) => {
            return (
              <div className="QualityGradeLi" key={li}>
                <div className="QualitySquare" style={{backgroundColor: Utils.QUALITY_COLORS[li]}}></div>
                {Utils.QUALITY_DESC[li]}
              </div>
            );
          })
          }
        </div>
      </div>);
  }

}