import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Route, Switch} from "react-router-dom";
import MainPage from "./MainPage";
const ROUTE_ROOTPATH ="(/jiaoda-air-web)?/";  // 本地是根目录，兼容服务器上带前缀目录

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path={ROUTE_ROOTPATH+"theme-:theme.html"}   component={MainPage} />
        <Route path={ROUTE_ROOTPATH}   component={MainPage} />
      </Switch>
    </div>
  );
}

export default App;
